<template>
  <div class="redeem">
    <Header />
    <template v-if="Object.keys(this.redeemTrx).length">
      <b-container class="redeem-container">
        <b-row class="justify-content-center">
          <div class="col-lg-9 col-md-6">
            <b-card class="p-4">
              <div class="d-block text-center">
                <img
                  src="@/assets/img/icons/transactions/checked-blue.svg"
                  alt=""
                />
                <h1 class="header-modal p-4 my-2">
                  Terdapat transaksi penjualan yang sedang berlangsung pada
                  produk ini.
                </h1>
                <b-row class="justify-content-center">
                  <b-button
                    to="/orders"
                    block
                    class="mt-3 mx-2 btn-tertiary py-2"
                    >Cek halaman transaksi</b-button
                  >
                </b-row>
              </div>
            </b-card>
          </div>
        </b-row>
      </b-container>
    </template>

    <template v-else>
      <b-container class="redeem-container">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <div class="row justify-content-center mx-auto my-auto">
              <div class="text-center">
                <img src="@/assets/img/loading/loading.gif" width="100px" />
              </div>
            </div>
          </template>
          <b-row class="justify-content-md-start back-nav">
            <div class="col-lg-12 col-md-6">
              <b-link to="/portfolio"
                ><b-icon class="mx-2" icon="chevron-left"></b-icon
                ><span>Kembali</span></b-link
              >
            </div>
          </b-row>

          <b-row class="justify-content-md-center p-4">
            <b-card class="redeem-order p-4">
              <b-form>
                <div class="product-name">
                  <b-row>
                    <div class="col-2 col-lg-1 text-left mb-2">
                      <img :src="imageCore + '/storage/logo/' +
                                      portfolio?.product?.investment_manager?.logo
                                      " />
                    </div>
                    <div class="col-10 text-left px-4">
                      <h4>
                        {{ portfolio.product.fund_name }}
                      </h4>
                      <p>
                        {{ portfolio.product.investment_manager.im_name }}
                      </p>
                    </div>
                  </b-row>
                </div>

                <div class="input-nominal-title">
                  <b-row>
                    <div class="col-lg-6 col-md-6 text-left mb-2 my-auto">
                      <h2 class="my-3">Masukkan jual berapa?</h2>
                    </div>
                    <div
                      class="col-lg-6 col-md-6 text-right mb-2 my-auto"
                      v-if="showSellAll"
                    >
                      <b-form-checkbox
                        id="redeem-all"
                        name="redeem-all"
                        :checked="checkedAllSell"
                        @change="checkAll"
                      >
                        Jual Semua
                      </b-form-checkbox>
                    </div>
                  </b-row>
                </div>

                <div class="input-nominal mt-4 my-auto">
                  <input
                    name="radioSell"
                    :checked="radioA"
                    type="radio"
                    value="A"
                    class="nominal-radio"
                    @change="radioCheck('A')"
                  />
                  <b-row class="ml-3 mt-n2 mb-n4">
                    <div class="col-lg-6 col-md-6 my-auto text-left">
                      <div class="by-nominal">
                        <p>Berdasar Nominal</p>
                      </div>
                    </div>
                    <div
                      class="col-lg-6 col-md-6 my-auto text-left text-lg-right"
                    >
                      <div class="limit-nominal">
                        <p>
                          Tersedia
                          {{
                            user?.individual?.accounts[0]?.currencies?.currency ||
                            user?.institutional?.accounts[0]?.currencies?.currency
                          }}{{ formatPrice(amount_from_porto_detail) }}
                        </p>
                      </div>
                    </div>
                  </b-row>

                  <b-row class="p-4">
                    <b-form-input
                      id="nominal-input"
                      v-model="sell_nominal"
                      @change="input_nominal()"
                      @click="click_nominal()"
                      type="text"
                      :min="0"
                      :max="amount_from_porto_detail_input"
                      @keypress="isNumber($event)"
                      placeholder="IDR"
                    >
                    </b-form-input>
                  </b-row>
                  <b-row class="p-4 mt-n4">
                    <div class="limit-nominal">
                      <p>
                        Nominal adalah estimasi berdasarkan harga NAV/unit
                        {{
                          moment(porto_detail.nav_date).format(" DD MMM YYYY")
                        }}.
                      </p>
                    </div>
                  </b-row>
                  <input
                    name="radioSell"
                    :checked="radioB"
                    type="radio"
                    value="B"
                    class="unit-radio"
                    @change="radioCheck('B')"
                  />

                  <b-row class="ml-3 mt-n2 mb-n4">
                    <div class="col-lg-6 col-md-6 my-auto text-left">
                      <div class="by-unit">
                        <p>Berdasar Unit</p>
                      </div>
                    </div>
                    <div
                      class="col-lg-6 col-md-6 my-auto text-left text-lg-right"
                    >
                      <div class="limit-unit">
                        <p>
                          Tersedia
                          <!-- {{ porto_detail.balance_unit | formatDecimal }} unit -->
                          {{ formatNab(portfolio.outstanding_unit_balance) }}
                          unit
                        </p>
                      </div>
                    </div>
                  </b-row>

                  <b-row class="p-4">
                    <b-form-input
                      v-model="sell_unit"
                      id="unit-input"
                      type="text"
                      :min="0"
                      @change="input_unit()"
                      @click="click_unit()"
                      @keypress="isNumber($event)"
                      :max="amount_from_porto_detail_input"
                      placeholder="Unit"
                    >
                    </b-form-input>
                  </b-row>
                </div>

                <hr />

                <div class="bank-info mt-4">
                  <div class="bank-title">
                    <b-row class="justify-content-md-start ml-1 mb-4">
                      <div class="bank-box">
                        <b-row>
                          <b-col>
                            <div class="bank-information">
                              <b-row>
                                <h2 class="ml-3">Rekening Bank Anda</h2>
                              </b-row>
                              <b-row>
                                <p class="ml-3">
                                  Uang hasil penjualan akan ditransfer oleh Bank
                                  Kustodian.
                                </p>
                              </b-row>
                            </div>
                            <template>
                              <div class="dropbox">
                                <b-row>
                                  <div class="col-lg-3 col-md-6">
                                    <div
                                      class="bank-image text-center mx-4 mt-2"
                                    >
                                      <div v-if="user.individual != null">
                                        <div v-if="accounts?.banks?.logo != null">
                                          <img
                                            :src="`${imageCore}/${accounts?.banks?.logo}`"
                                            max-height="26"
                                            max-width="86"
                                            alt="Bank Image"
                                          />
                                        </div>
                                        <div v-else>
                                          <img
                                            :src="
                                              'https://ui-avatars.com/api/?background=EB321E&color=fff&name=' +
                                              accounts?.banks?.bank_name
                                            "
                                            max-height="26"
                                            max-width="86"
                                            alt="Bank Image"
                                          />
                                        </div>
                                      </div>
                                      <div v-else>
                                        <div
                                          v-if="
                                            user?.institutional?.account.banks
                                              .logo != null
                                          "
                                        >
                                          <img
                                            :src="`${imageCore}/${user?.institutional?.account.banks.logo}`"
                                            max-height="26"
                                            max-width="86"
                                            alt="Bank Image"
                                          />
                                        </div>
                                        <div v-else>
                                          <img
                                            :src="
                                              'https://ui-avatars.com/api/?background=EB321E&color=fff&name=' +
                                              user?.institutional?.account.banks
                                                .bank_name
                                            "
                                            max-height="26"
                                            max-width="86"
                                            alt="Bank Image"
                                          />
                                        </div>
                                      </div>
                                      <p class="mt-1">
                                        {{
                                          accounts?.banks?.bank_name ||
                                          user?.institutional?.accounts[0]?.banks
                                            .bank_name
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-9 col-md-6 text-center">
                                    <div class="payment-account mt-1">
                                      <p>
                                        {{
                                          accounts?.account_number ||
                                          user?.institutional?.accounts[0]?.account_number
                                        }}
                                      </p>
                                      <h3>
                                        {{
                                          accounts?.account_name ||
                                          user?.institutional?.accounts[0]?.account_name
                                        }}
                                      </h3>
                                    </div>
                                  </div>
                                </b-row>
                              </div>
                            </template>
                          </b-col>
                        </b-row>
                      </div>
                    </b-row>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-6">
                    <b-button
                      block
                      @click="nextReview()"
                      class="mt-2 btn-tertiary py-2"
                      >Lanjut</b-button
                    >
                  </div>
                </div>
              </b-form>
            </b-card>
          </b-row>
        </b-skeleton-wrapper>
      </b-container>
    </template>

    <div>
      <b-modal
        id="modal-failed-register"
        v-model="unitLimit"
        size="md"
        class="modal-failed-register"
        centered
        hide-footer
      >
        <div class="d-block text-center">
          <img
            src="@/assets/img/ekyc/data-not-correct.svg"
            alt="data-not-correct"
          />
          <h1 class="header-modal p-4 my-2 text-dark">
            Jumlah Unit tidak tersedia!
          </h1>
          <p>Cek kembali jumlah unit Anda.</p>
        </div>
      </b-modal>
      <b-modal
        id="modal-failed-register"
        v-model="nominalLimit"
        size="md"
        class="modal-failed-register"
        centered
        hide-footer
      >
        <div class="d-block text-center">
          <img
            src="@/assets/img/ekyc/data-not-correct.svg"
            alt="data-not-correct"
          />
          <h1 class="p-4 my-2 text-dark">Jumlah nominal tidak tersedia!</h1>
          <p>Cek kembali jumlah nominal Anda.</p>
        </div>
      </b-modal>
      <b-modal
        id="modal-failed-register"
        v-model="unitLimitSell"
        size="md"
        class="modal-failed-register"
        centered
        hide-footer
      >
        <div class="d-block text-center">
          <img
            src="@/assets/img/ekyc/data-not-correct.svg"
            alt="data-not-correct"
          />
          <h1 class="header-modal p-4 my-2 text-dark">
            Jumlah Unit tidak mencukupi untuk dijual!
          </h1>
          <p>Cek kembali jumlah unit Anda.</p>
        </div>
      </b-modal>
      <b-modal
        id="modal-failed-register"
        v-model="nominalLimitSell"
        size="md"
        class="modal-failed-register"
        centered
        hide-footer
      >
        <div class="d-block text-center">
          <img
            src="@/assets/img/ekyc/data-not-correct.svg"
            alt="data-not-correct"
          />
          <h1 class="header-modal p-4 my-2 text-dark">
            Jumlah nominal tidak mencukupi untuk dijual!
          </h1>
          <p>Cek kembali jumlah nominal Anda.</p>
        </div>
      </b-modal>
      <b-modal
        id="checkModal2"
        ref="checkModal2"
        size="md"
        class="modal-failed-register"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <div class="d-block text-center">
          <img
            src="@/assets/img/ekyc/verification-proccess.svg"
            alt="data-not-correct"
          />
          <h1 class="p-4 my-2 text-dark">Akun Ditangguhkan</h1>
          <p>Akun anda sementara ditangguhkan</p>
          <b-row class="justify-content-md-end btn-submit-row mb-4">
            <div class="col-lg-12 col-md-6">
              <b-button
                size="sm"
                class="btn-tertiary px-4 py-2"
                click="closeCheckData2"
                >Kembali</b-button
              >
            </div>
          </b-row>
        </div>
      </b-modal>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import numeral from "numeral";
import { encryptData } from '@/utils'
const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "Redeem",
  metaInfo: {
    title: "Penjualan | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      imageCore: process.env.VUE_APP_IMAGE_URL,
      sell_nominal: 0,
      sell_unit: 0,
      sell_all_unit: "",
      value: 2,
      nominalChecked: false,
      unitChecked: false,
      unitLimit: false,
      nominalLimit: false,
      unitLimitSell: false,
      nominalLimitSell: false,
      portfolio: {
        product: {
          fund_name: "",
          investment_manager: {
            im_name: "",
            nav: {
              nav_per_unit: "",
              nav_date: "",
            },
          },
        },
      },
      porto_detail: {
        nav_per_unit: "",
      },
      fundcode: null,
      detail: {},
      orders: [],
      accounts: {},
      product_id: null,
      showSellAll: true,
      checkedAllSell: false,
      radioA: false,
      radioB: false,
      amount_from_porto_detail: 0,
      amount_from_porto_detail_input: 0,
      unit_from_porto_detail_input: 0,
      unit_minimum_sell: 0,
      is_check: "",
      loading: false,
      loadingTime: 0,
      maxLoadingTime: 2,
      s21Client: null,
    };
  },
  computed: {
    ...mapGetters({
      access_token: "auth/access_token",
      bearer_token: "bearer_token",
      check: "auth/check",
      user: "auth/user",
      app_url: "app_url",
    }),

    formatPriceMax: function (value) {
      let val = Math.ceil(value).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    redeemTrx: function () {
      var fundCode = this.$route.params.fund_code;
      return this.orders.filter(function (u) {
        return u.id_transaction_type == 2 && u.fund_code == fundCode && u.status == 'waiting confirm';
      });
    },
  },
  mounted() {
    this.fund_code = this.$route.params.fund_code;
    this.product_id = this.$route.params.product_id;
    this.fetchPortfolio();
    this.fetchOrders();
    this.fetchDefaultAccount();
    if (this.user?.individual?.client_id) this.fetchS21Client();
  },
  created() {
    this.$_loadingTimeInterval = null;
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
  },
  methods: {
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async fetchDefaultAccount() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}individual-account/detail-account?individual_id=${this.user?.individual?.id}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });
      this.accounts = response.data.data;
    },
    async fetchOrders() {
      if (this.user.institutional != null) {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}order-get?ifua_number=${this.user?.institutional?.ifua}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        if (!response.data.data) {
          return this.$router.push("/NOT-FOUND");
        }
        this.orders = response.data.data;
      } else {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}order-get?ifua_number=${this.user?.individual?.ifua}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        if (!response.data.data) {
          return this.$router.push("/NOT-FOUND");
        }
        this.orders = response.data.data;
      }
    },
    async fetchPortfolio() {
      if (this.user.institutional == null) {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}user-portfolio-product?type=Individual&id=${this.user?.individual?.id}&fund_code=${this.fund_code}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.portfolio = response?.data?.data?.userPortfolio[0];
        this.porto_detail = response?.data?.data?.portoDetail;
        // this.amount_from_porto_detail = this.porto_detail.balance_unit * this.porto_detail.nav_per_unit;
        this.amount_from_porto_detail = parseInt(this.portfolio.amount);
        this.amount_from_porto_detail_input = this.formatPriceInput(
          this.amount_from_porto_detail
        );
        this.unit_minimum_sell =
          this.portfolio.product.min_sell / this.porto_detail.nav_per_unit;
        this.unit_from_porto_detail_input = numeral(
          this.portfolio.outstanding_unit_balance
        ).format("0,0.0000");
      } else {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}user-portfolio-product?type=Institutional&id=${this.user?.institutional?.id}&fund_code=${this.fund_code}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.portfolio = response.data.data.userPortfolio[0];
        this.porto_detail = response.data.data.portoDetail;
        // this.amount_from_porto_detail = this.porto_detail.balance_unit * this.porto_detail.nav_per_unit;
        this.amount_from_porto_detail = parseInt(this.portfolio.amount);
        this.unit_minimum_sell =
          this.portfolio.product.min_sell / this.porto_detail.nav_per_unit;
        this.amount_from_porto_detail = this.formatPriceInput(
          this.amount_from_porto_detail
        );
        this.unit_from_porto_detail_input = numeral(
          this.porto_detail.balance_unit
        ).format("0,0.0000");
      }
    },

    checkAll(event) {
      if (event.target.checked) {
        this.radioA = false;
        this.radioB = false;
        this.checkedAllSell = true;
        localStorage.sell_all_unit = "Y";
        localStorage.is_check = "unit";
        this.sell_unit = this.unit_from_porto_detail_input;
        this.sell_nominal = this.amount_from_porto_detail_input;
      } else {
        localStorage.sell_all_unit = "";
      }
    },

    radioCheck(val) {
      if (val == "A") {
        this.selected = "A";
        this.radioB = false;
        this.checkedAllSell = false;
        localStorage.is_check = "nominal";
        localStorage.sell_all_units = "";
      } else if (val == "B") {
        this.selected = "B";
        this.radioA = false;
        this.checkedAllSell = false;
        localStorage.is_check = "unit";
        localStorage.sell_all_units = "";
      }
    },

    click_nominal() {
      this.sell_nominal = null;
      this.radioA = true;
      this.radioB = false;
      localStorage.is_check = "nominal";
      this.checkedAllSell = false;
      localStorage.sell_all_units = "";
    },

    input_nominal() {
      this.radioA = true;
      this.radioB = false;
      localStorage.is_check = "nominal";
      this.checkedAllSell = false;
      localStorage.sell_all_units = "";
      if (
        this.sell_nominal >
        // this.porto_detail.balance_unit * this.porto_detail.nav_per_unit
        this.amount_from_porto_detail
      ) {
        this.nominalLimit = true;
        this.sell_nominal =
          // this.porto_detail.balance_unit * this.porto_detail.nav_per_unit;
          this.amount_from_porto_detail;
        // this.sell_unit = this.sell_nominal / this.porto_detail.nav_per_unit;
        this.sell_unit = this.sell_nominal / this.porto_detail.nav_per_unit;
      } else {
        this.sell_unit = this.sell_nominal / this.porto_detail.nav_per_unit;
      }
    },

    click_unit() {
      this.sell_unit = null;
      this.radioA = false;
      this.radioB = true;
      localStorage.is_check = "unit";
      this.checkedAllSell = false;
      localStorage.sell_all_units = "";
    },

    input_unit() {
      this.radioA = false;
      this.radioB = true;
      localStorage.is_check = "unit";
      this.checkedAllSell = false;
      localStorage.sell_all_units = "";
      // if (this.sell_unit > this.porto_detail.balance_unit) {
      if (this.sell_unit > parseInt(this.portfolio.outstanding_unit_balance)) {
        this.unitLimit = true;
        this.sell_unit = this.portfolio.outstanding_unit_balance;
        this.sell_nominal = this.sell_unit * this.porto_detail.nav_per_unit;
      } else {
        this.sell_nominal = this.sell_unit * this.porto_detail.nav_per_unit;
      }
    },

    slideNominal() {
      this.radioA = true;
      this.radioB = false;
      localStorage.is_check = "nominal";
      this.checkedAllSell = false;
      localStorage.sell_all_units = "";
      this.sell_unit = this.sell_nominal / this.porto_detail.nav_per_unit;
      this.sell_unit = numeral(this.sell_unit).format("0,0.0000");
    },

    formatPrice(value) {
      let val = Math.ceil(value).toFixed(2).replace(".", ",");

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatPriceInput(value) {
      return Math.ceil(value);
    },

    slideUnit() {
      this.radioA = false;
      this.radioB = true;
      localStorage.is_check = "unit";
      this.checkedAllSell = false;
      localStorage.sell_all_units = "";
      this.sell_nominal = this.formatPriceInput(
        this.sell_unit * this.porto_detail.nav_per_unit
      );
    },

    nextReview() {
      if (!this.s21Client) return alert("Gagal mengambil data client");
      else if (
        this.s21Client?.suspendsell === 1 &&
        this.s21Client?.suspend === 1
      )
        return this.checkData2();

      if (this.portfolio.product.min_sell == null) {
        if (this.checkedAllSell == true) {
          localStorage.sell_nominal = parseInt(this.sell_nominal);
          localStorage.sell_unit = this.sell_unit;
          this.$router.push("/redeem-review/" + this.fund_code);
        } else if (parseInt(this.sell_nominal) < 10000) {
          this.nominalLimitSell = true;
          return false;
        } else if (this.sell_unit < 10000 / this.porto_detail.nav_per_unit) {
          this.unitLimitSell = true;
          return false;
        } else if (this.sell_unit > this.portfolio.outstanding_unit_balance) {
          this.unitLimitSell = true;
          return false;
        } else {
          localStorage.sell_nominal = parseInt(this.sell_nominal);
          localStorage.sell_unit = this.sell_unit;
          this.$router.push("/redeem-review/" + this.fund_code);
        }
      } else {
        if (this.checkedAllSell == true) {
          localStorage.sell_nominal = parseInt(this.sell_nominal);
          localStorage.sell_unit = this.sell_unit;
          this.$router.push("/redeem-review/" + this.fund_code);
        } else if (
          parseInt(this.sell_nominal) <
          parseInt(this.portfolio.product.min_sell)
        ) {
          this.nominalLimitSell = true;
          return false;
        } else if (
          this.sell_unit <
          this.portfolio.product.min_sell / this.porto_detail.nav_per_unit
        ) {
          this.unitLimitSell = true;
          return false;
        } else {
          localStorage.sell_nominal = parseInt(this.sell_nominal);
          localStorage.sell_unit = this.sell_unit;
          this.$router.push("/redeem-review/" + this.fund_code);
        }
      }
    },
    checkData2() {
      if (this.$refs["checkModal2"]) this.$refs["checkModal2"].show();
    },
    closeCheckData2() {
      if (this.$refs["checkModal2"]) this.$refs["checkModal2"].hide();
    },
    async fetchS21Client() {
      let response = await axios({
        method: "GET",
        url: `${this.app_url}s21client?clientId=${encryptData(this.user?.individual?.client_id)}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      this.s21Client = response.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.redeem {
  background: #f3f4f6;
}
.redeem-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.redeem-review {
  padding: 20px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.back-nav {
  padding: 0px 260px 0px 248px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #6b7280;
    text-decoration: none;
  }
}
.con-vs-checkbox {
  justify-content: end;
  margin: 0;
}

.con-vs-radio {
  justify-content: start;
}

.redeem-order {
  width: 600px;
}

.product-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
  }
}

.input-nominal-title {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

hr {
  margin: 0 !important;
}

.by-nominal {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #374151;
  }
}

.limit-nominal {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }
}

.custom-range::-webkit-slider-thumb {
  background: $primaryColor;
}

.custom-range::-moz-range-thumb {
  background: $primaryColor;
}

.custom-range::-ms-thumb {
  background: $primaryColor;
}

.nominal-radio {
  position: relative;
  top: 18px;
  left: 8px;
}

.unit-radio {
  position: relative;
  top: 18px;
  left: 8px;
}

.by-unit {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #374151;
  }
}

.limit-unit {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }
}

.form-control:focus {
  background: #f7f7f8;
  outline: none !important;
  border: 1px solid $primaryColor !important;
  box-shadow: none !important;
}

.bank-information {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
    margin-bottom: 0px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }
}

.dropbox {
  outline: 1px solid #d1d5db;
  background: white;
  color: dimgray;
  padding: 10px 10px;
  height: 100px; /* minimum height */
  width: 500px; /* minimum height */
  position: relative;
  border-radius: 5px;
}

.bank-image {
  img {
    width: 40px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #4b5563;
    margin-bottom: 0px;
  }
}

.payment-account {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
    margin-bottom: 0px;
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.header-modal {
  font-size: 18px;
}
@media only screen and (max-width: 768px) {
  .back-nav {
    padding: 0px;
  }
  .dropbox {
    width: 90%;
    height: 200px;
  }
  h2 {
    font-size: 16px !important;
  }
  h4 {
    font-size: 16px !important;
  }
  p {
    font-size: 14px !important;
  }
  .header-modal {
    font-size: 16px !important;
  }
}
</style>
